<template>
  <div>
    <div class="animated fadeIn">
      <div id="snackbar"></div>
      <b-card-header class="mb-3">
        <b-row>
          <b-col> <i class="icon-menu mr-1"></i>Manual Schedule Sync </b-col>
        </b-row>
      </b-card-header>

      <b-card>
        <div class="row">
          <b-col sm="3">
            <b-form-group>
              <label for="from-date">From Date :</label>
              <b-form-datepicker
                id="from-datepicker"
                v-model="scheduleForm.fromDate"
                class="mb-2"
                :min="min"
                :max="max"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                @input="dateDifference(scheduleForm.fromDate)"
                locale="en"
              ></b-form-datepicker>
              <span
                class="error"
                v-if="!scheduleForm.fromDate || errorList.fromDate"
              >
                {{ errorList.fromDate }}
              </span>
            </b-form-group>
          </b-col>

          <b-col sm="3">
            <b-form-group>
              <label for="to-date">To Date :</label>

              <b-form-datepicker
                id="to-datepicker"
                v-model="scheduleForm.toDate"
                class="mb-2"
                :min="minTo"
                :max="maxTo"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="en"
              ></b-form-datepicker>
              <span
                class="error"
                v-if="!scheduleForm.toDate || errorList.toDate"
              >
                {{ errorList.toDate }}
              </span>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group>
              <label for="from-date">Select Cinema Name</label>
              <b-form-select
                v-model="scheduleForm.cinema"
                id="cinemas"
                :plain="true"
                text-field="CinemaName"
                value-field="CinemaName"
                :options="$store.getters['common/getAllCinemas']"
                @change="getMovie"
              >
                <!-- text-field="CinemaName"
                value-field="CinemaID"-->
                <template slot="first">
                  <option :value="'0'" disabled>-- Select Cinema --</option>
                </template>
              </b-form-select>
              <span
                class="error"
                v-if="!scheduleForm.cinema || errorList.cinema"
              >
                {{ errorList.cinema }}
              </span>
            </b-form-group>
          </b-col>
          <!-- <b-col sm="3">
            <b-form-group>
              <label for="from-date">Movie Name</label>
              <b-form-select
                required
                v-model="scheduleForm.film"
                id="movie"
                :plain="true"
                text-field="movie"
                value-field="FilmID"
                :options="MovieList"
              >
                <template slot="first">
                  <option :value="'0'" disabled>-- Select Movie Name --</option>
                </template>
              </b-form-select>
              <span class="error" v-if="!scheduleForm.film || errorList.film">
                {{
                errorList.film
                }}
              </span>
            </b-form-group>
          </b-col> -->
        </div>
        <div class="row">
          <b-col sm="12" class="text-center">
            <b-button
              class="ml-2"
              type="submit"
              size="sm"
              variant="primary"
              @click="submitSchedule"
              >Get Schedules</b-button
            >
            <b-button
              class="ml-2"
              type="submit"
              size="sm"
              variant="primary"
              @click.prevent="resetForm"
              >Reset</b-button
            >
          </b-col>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import MasterService from "@/services/MasterService";
import axios from "axios";
import moment from "moment";
import vSelect from "vue-select";

export default {
  name: "InternalAccountsReport",
  components: {
    vSelect,
  },
  watch: {},
  data: function () {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 0);
    minDate.setDate(now.getDate());
    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 2);
    // maxDate.setDate(15);
    return {
      min: minDate,
      max: "",
      minTo: "",
      maxTo: "",
      DatePickerFormat: "MM/dd/yyyy",
      disabledDates: {
        to: new Date(Date.now()),
      },

      scheduleForm: {
        fromDate: "",
        toDate: "",
        cinema: "0",
      },
      MovieList: [],
      scheduleFormError: [
        "Please Select From Date",
        "Please Select To Date",
        "Please Select Cinem",
      ],
      errorList: {
        fromDate: "",
        toDate: "",
        cinema: "",
      },
      validate: false,

      useVuex: false,
      theme: "bootstrap4",
      theme1: "bootstrap4",
      theme2: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715",
      },
      isLoader: false,
    };
  },
  beforeMount() {
    let payload = {};

    payload = {
      OrgID: 3,
    };
    this.$store.dispatch("common/setAllCinemas", payload);
  },
  mounted() {
    MasterService.getCinecoSession()
      .then((response) => {
        this.MovieList = response.data.SessionData;
        console.log(this.detailsList, "detailssssssss");
      })
      .catch((error) => {
        console.log("Catch on Getting Event Error: ", error);
      });
  },
  methods: {
    // By Priyanshu Singh 24/12/2021 Start
    dateDifference(fromDate) {
      this.scheduleForm.toDate = "";
      const now = new Date(fromDate);
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const countDays = now.getDate() + 4;
      // 15th two months prior
      const minDate = new Date(today);
      minDate.setMonth(minDate.getMonth() - 0);
      minDate.setDate(now.getDate());
      // 15th in two months
      const maxDate = new Date(today);
      maxDate.setMonth(maxDate.getMonth() + 0);
      maxDate.setDate(countDays);

      this.minTo = minDate;
      this.maxTo = maxDate;
    },
    moment: function (date) {
      return moment(date);
    },
    getMovie() {
      if (this.scheduleForm.cinema) {
        let payload = {
          CinemaID: this.scheduleForm.cinema,
        };
        this.MovieList = [];

        MasterService.getCinecoSession()
          .then((response) => {
            this.MovieList = response.data.SessionData;
            console.log(this.detailsList, "detailssssssss");
          })
          .catch((error) => {
            console.log("Catch on Getting Event Error: ", error);
          });
      }
    },
    clearErrVal() {
      this.errorList = {
        fromDate: "",
        toDate: "",
        cinema: "",
      };
    },
    resetForm: function () {
      this.scheduleForm = {
        fromDate: "",
        toDate: "",
        cinema: "0",
      };
      this.errorList = {
        fromDate: "",
        toDate: "",
        cinema: "",
      };
    },
    validateSchedule() {
      this.clearErrVal();
      let validate_new = true;
      Object.keys(this.errorList).map((x, index) => {
        if (
          this.scheduleForm[x] == "" ||
          this.scheduleForm[x] == undefined ||
          this.scheduleForm[x] == null ||
          this.scheduleForm[x] == 0
        ) {
          this.errorList[x] = this.scheduleFormError[index];
          validate_new = false;
        }
      });
      if (validate_new != true) {
        this.validate = false;
        return;
      } else {
        this.validate = true;
      }
    },
    submitSchedule: function () {
      this.validateSchedule();
      if (this.validate == false) return false;
      let cinemaName =
        this.scheduleForm.cinema.indexOf("Hamra") >= 0
          ? "alhamra"
          : this.scheduleForm.cinema.indexOf("Seef") >= 0
          ? "seef"
          : this.scheduleForm.cinema.indexOf("Oasis") >= 0
          ? "oasis"
          : this.scheduleForm.cinema.indexOf("Wadi") >= 0
          ? "wadi"
          : 0;

      let fd = new Date(this.scheduleForm.fromDate);
      let td = new Date(this.scheduleForm.toDate);
      let fromDate =
        String(fd.getMonth() + 1).padStart(2, "0") +
        "/" +
        String(fd.getDate()).padStart(2, "0") +
        "/" +
        fd.getFullYear();
      let toDate =
        String(td.getMonth() + 1).padStart(2, "0") +
        "/" +
        String(td.getDate()).padStart(2, "0") +
        "/" +
        td.getFullYear();
      let FilmKey = 0;
      // if (isNaN(this.scheduleForm.film) == true) {
      //   FilmKey = 0;
      // } else {
      //   FilmKey = parseInt(this.scheduleForm.film);
      // }
      let payload = {
        // scheduleForm: this.scheduleForm,
        filmKey: FilmKey,
        cinema: cinemaName,
        fromDate: fromDate,
        toDate: toDate,
      };
      console.log(payload);
      // let payload = {
      //   cinema: cinemaName,
      //   filmKey: items.FilmID
      // };
      let options = {
        method: "POST",
        url: `https://api-v2-wanasatime.wanasatime.com/api/cineco/schedule-manual-sync`,
        data: JSON.stringify(payload),
        crossDomain: true,

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      axios(options)
        .then((result) => {
          // const { statusText, status, data } = result;
          // if (status == 200 && data) {
          const { data } = result;
          console.log("response Happy=>", data);

          if (data.status) {
            this.resetForm();
            this.showMessageSnackbar("Data is Updated SuccessFully");
          } else {
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          }
          this.singleCinecoDetails = data.data.recordset;
          this.isLoader = false;
          // }
        })
        .catch((error) => {});

      // return false;
      // MasterService.getManualScheduleSync(payload)
      //   .then(response => {
      //     const { data } = response;
      //     console.log("response Happy=>",data)
      //     this.showMessageSnackbar(data.Message);

      //     if (data && data.Status) {
      //       this.resetForm();
      //     }
      //   })
      //   .catch(error => {
      //     console.log("Catch on AddUser Error: ", error);
      //     this.showMessageSnackbar(
      //       "Oops! Something went wrong. kindly contact your administrator"
      //     );
      //   });
    },
    // Show Error Message
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
  computed: {},
  created() {},
};
</script>

<style>
label {
  font-weight: 600;
}
.table_class {
  overflow: auto;
}
td {
  padding: 0px;
}
.table-bordered thead th {
  border: 1px solid white;
}
.pagination_button {
  padding: 8px;
  margin: 2px;
  border-radius: 3px;
  font-size: 1em;
  cursor: pointer;
}
.pagination-row {
  padding: 5px 0;
}

.my-custom-scrollbar {
  position: relative;
  height: 850px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
.error {
  color: red;
}
</style>
